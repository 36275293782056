import React, { useState, useEffect } from "react";

const Modal = ({ onClose, onSave, item, onDelete }) => {
  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [qty, setQty] = useState("");
  const [unitCost, setUnitCost] = useState("");

  useEffect(() => {
    if (item) {
      // Populate fields when editing an existing item
      setDescription(item.description || "");
      setUnit(item.unit || "");
      setQty(item.qty !== undefined ? String(item.qty) : "");
      setUnitCost(item.unitCost !== undefined ? String(item.unitCost) : "");
    } else {
      // Reset fields when adding a new item
      setDescription("");
      setUnit("");
      setQty("");
      setUnitCost("");
    }
  }, [item]);

  const handleSave = () => {
    onSave({
      description,
      unit,
      qty: qty.trim() || "1", // Default to "1" if empty
      unitCost: parseFloat(unitCost) || 0, // Default to "0" if empty
    });
  };

  return (
    <div className="doc-modal">
      <div className="doc-modal-content">
        <h2 className="doc-modal-header">{item ? "Edit Item" : "Add Item"}</h2>

        {/* Description Field */}
        <div className="doc-modal-field">
          <label className="doc-modal-label" htmlFor="description">
            Description
          </label>
          <input
            id="description"
            className="doc-modal-input"
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        {/* Unit Field */}
        <div className="doc-modal-field">
          <label className="doc-modal-label" htmlFor="unit">
            Unit
          </label>
          <input
            id="unit"
            className="doc-modal-input"
            type="text"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
          />
        </div>

        {/* Quantity Field */}
        <div className="doc-modal-field">
          <label className="doc-modal-label" htmlFor="qty">
            Quantity
          </label>
          <input
            id="qty"
            className="doc-modal-input"
            type="text"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
          />
        </div>

        {/* Unit Cost Field */}
        <div className="doc-modal-field">
          <label className="doc-modal-label" htmlFor="unitCost">
            Unit Cost
          </label>
          <input
            id="unitCost"
            className="doc-modal-input"
            type="number"
            value={unitCost}
            onChange={(e) => setUnitCost(e.target.value)}
          />
        </div>

        <div className="doc-modal-actions">
          {item ? (
            <button
              className="doc-modal-button doc-modal-delete-button"
              onClick={onDelete}
            >
              Delete
            </button>
          ) : (
            <div></div>
          )}
          <div className="doc-modal-actions-right">
            <button
              className="doc-modal-button doc-modal-cancel-button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="doc-modal-button doc-modal-save-button"
              onClick={handleSave}
            >
              {item ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
