import React from "react";

const Summary = ({ summary }) => {
  return (
    <div className="doc-summary">
      <table className="doc-summary-table">
        <tbody>
          <tr>
            <td className="doc-summary-label">Subtotal:</td>
            <td className="doc-summary-currency">$</td>
            <td className="doc-summary-value">
              {summary.subtotal.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
          <tr>
            <td className="doc-summary-label">GST (5%):</td>
            <td className="doc-summary-currency">$</td>
            <td className="doc-summary-value">
              {summary.gst.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
          <tr>
            <td className="doc-summary-label">Total Cost:</td>
            <td className="doc-summary-currency">$</td>
            <td className="doc-summary-value">
              {summary.total.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Summary;
