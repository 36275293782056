import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import photo from "../assets/photos/home-photo.jpg";
import "./Home.css";

const Home = () => {
  const [animateText, setAnimateText] = useState(false);
  const [animateCaption, setAnimateCaption] = useState(false);
  const [animateButton, setAnimateButton] = useState(false);
  const [animatePage, setAnimatePage] = useState(false);
  const [photoLoaded, setPhotoLoaded] = useState(false);

  useEffect(() => {
    const textTimer = setTimeout(() => setAnimateText(true), 200);
    const captionTimer = setTimeout(() => setAnimateCaption(true), 600);
    const buttonTimer = setTimeout(() => setAnimateButton(true), 1000);
    const pageTimer = setTimeout(() => setAnimatePage(true), 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(captionTimer);
      clearTimeout(buttonTimer);
      clearTimeout(pageTimer);
    };
  }, []);

  return (
    <div>
      {/* Attention Section */}
      <section className="attention-section">
        <div className="attention-text-container">
          <h2 className={`attention-text ${animateText ? "fade-in" : ""}`}>
            Your Experts for Residential and Commercial Stone Installation and
            Replacement in the Greater Calgary Area
          </h2>
          <div
            className={`attention-caption ${animateCaption ? "fade-in" : ""}`}
          >
            Let us help you bring your vision to life!
          </div>
        </div>
        <Link
          to="/contact"
          className={`attention-cta-button ${animateButton ? "fade-in" : ""}`}
        >
          CONTACT US
        </Link>
      </section>

      {/* Main Content */}
      <div className={`home-container ${animatePage ? "fade-in" : ""}`}>
        <section className="about-section">
          <p className="hero-description">
            <strong>Express Granite and Quartz Ltd.</strong> is a family-owned
            business based in Calgary, Alberta, with over 20 years of experience
            in the granite and quartz industry. We pride ourselves on delivering
            high-quality surfaces with a focus on craftsmanship, reliability,
            and customer satisfaction.
          </p>
        </section>
        <div className="home-content">
          <section className="hero-section">
            <h1 className="hero-title">Our Services</h1>
            <p className="hero-description">
              Our team follows manufacturer guidelines, building codes, and
              industry standards to ensure every installation is durable and
              expertly executed. We are committed to providing beautiful,
              long-lasting surfaces that enhance your space.
            </p>
          </section>

          <section className="services-section">
            <ul className="services-list">
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>New constructions</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Renovations</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Crack/seam repairs</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Full-height backsplash</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Color-matching</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Resizing of sink cutouts</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Demolition</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Custom hole-drilling</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Sink remounting</strong>
                </span>
              </li>
              <li className="service-item">
                <div className="icon-container">
                  <FaCheck className="checkmark-icon" />
                </div>
                <span className="service-text">
                  <strong>Design consultation</strong>
                </span>
              </li>
            </ul>
          </section>
        </div>

        <div className="home-photo-container">
          <img
            className={`home-photo ${photoLoaded ? "loaded" : ""}`}
            src={photo}
            alt="Home Granite Example"
            onLoad={() => setPhotoLoaded(true)}
          />
        </div>
        <section className="consultation-section">
          <div className="consultation-text-container">
            <h2 className="consultation-text">
              Not sure where to start? Request your FREE consultation today!
            </h2>
            <div className="consultation-caption">
              When you call us for a consultation, we'll come to your home at a
              time that's convenient for you. Whether you're looking to start
              from scratch or enhance an existing space, we'll work with you to
              develop a plan that fits your needs and your budget.
            </div>
            <Link to="/contact" className="consultation-cta-button">
              GET A FREE QUOTE
            </Link>
          </div>
        </section>
      </div>

      {/* Mobile Photo and Consultation Section */}
      <div
        className={`home-photo-container-mobile ${
          animatePage ? "fade-in" : ""
        }`}
      >
        <img
          className="home-photo-mobile"
          src={photo}
          alt="Home Granite Example"
        />
        <Link to="/projects" className="home-photo-mobile-cta">
          SEE GALLERY
        </Link>
      </div>
      <section
        className={`consultation-section-mobile ${
          animatePage ? "fade-in" : ""
        }`}
      >
        <div className="consultation-text-container">
          <h2 className="consultation-text">
            Not sure where to start? Request your FREE consultation today!
          </h2>
          <div className="consultation-caption">
            When you call us for a consultation, we'll come to your home at a
            time that's convenient for you. Whether you're looking to start from
            scratch or enhance an existing space, we'll work with you to develop
            a plan that fits your needs and your budget.
          </div>
          <Link to="/contact" className="consultation-cta-button">
            GET A FREE QUOTE
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
