import React from "react";

const FormField = ({ label, type, value, onChange, options }) => (
  <div className="doc-form-field">
    <label className="doc-form-field-label">{label}</label>
    {type === "select" ? (
      <select
        className="doc-form-field-select"
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    ) : (
      <input
        className="doc-form-field-input"
        type={type}
        value={value}
        onChange={onChange}
      />
    )}
  </div>
);

export default FormField;
