import React from "react";

const Table = ({ items, onRowClick }) => {
  return (
    <table className="doc-preview-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Description</th>
          <th>Unit</th>
          <th>Qty</th>
          <th>Unit Cost</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr
            key={index}
            className="doc-preview-table-row"
            onClick={() => onRowClick(index)}
          >
            <td>{index + 1}</td>
            <td>{item.description}</td>
            <td>{item.unit}</td>
            <td>{item.qty}</td>
            <td>{item.unitCost.toFixed(2)}</td>
            <td>{item.total.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
