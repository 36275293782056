import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Projects.css";

// Dynamically import all images from src/assets/projects
const importAll = (requireContext) => {
  return requireContext.keys().map(requireContext);
};
const images = importAll(
  require.context("../assets/projects", false, /\.(png|jpe?g|svg)$/)
);

const Projects = () => {
  const [selectedImage, setSelectedImage] = useState(null); // Tracks the image to show in the modal
  const [delayedLoadedImages, setDelayedLoadedImages] = useState(new Set()); // Ensures delay before removing blur
  const [titleLoaded, setTitleLoaded] = useState(false); // Tracks the animation state of the title

  useEffect(() => {
    const titleTimer = setTimeout(() => setTitleLoaded(true), 200); // Delay the title animation
    return () => clearTimeout(titleTimer);
  }, []);

  const openModal = (image) => {
    setSelectedImage(image); // Set the clicked image as the modal image
  };

  const closeModal = () => {
    setSelectedImage(null); // Reset the modal image when closed
  };

  const goToPrevious = () => {
    const currentIndex = images.indexOf(selectedImage);
    const previousIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[previousIndex]);
  };

  const goToNext = () => {
    const currentIndex = images.indexOf(selectedImage);
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
  };

  const handleImageLoad = (index) => {
    setTimeout(() => {
      setDelayedLoadedImages((prevDelayed) => new Set(prevDelayed).add(index)); // Delay before transitioning to final state
    }, 600); // Adjust the delay (200ms) as needed
  };

  return (
    <div className="projects">
      <h1 className={`projects-title ${titleLoaded ? "fade-in-up" : ""}`}>
        Projects
      </h1>

      {/* Thumbnail Display */}
      <div className="thumbnails">
        {images.map((image, index) => (
          <LazyLoadImage
            key={index}
            src={image}
            alt={`Project ${index + 1}`}
            effect="blur" // Built-in effect for blur while loading
            className={`thumbnail ${
              delayedLoadedImages.has(index) ? "fade-in-up" : ""
            } ${selectedImage === image ? "active" : ""}`}
            onLoad={() => handleImageLoad(index)}
            onClick={() => openModal(image)}
          />
        ))}
      </div>

      {/* Modal for Full-Size Image */}
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div
            className="modal-navigation"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="nav-arrow nav-arrow-left" onClick={goToPrevious}>
              &#8249; {/* Left arrow icon */}
            </button>
            <button className="nav-arrow nav-arrow-right" onClick={goToNext}>
              &#8250; {/* Right arrow icon */}
            </button>
          </div>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Full-Size Project" />
            <button className="close-button" onClick={closeModal}>
              &times; {/* Close symbol */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
