import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import "./131.css";
import "../styles/form-fields.css";
import "../styles/table.css";
import "../styles/modal.css";
import "../styles/summary.css";
import "../styles/buttons.css";

import FormField from "../components/FormField";
import Table from "../components/Table";
import Modal from "../components/Modal";
import Summary from "../components/Summary";

// Function to get today's date in Mountain Time
const getMountainTimeDate = () => {
  const formatter = new Intl.DateTimeFormat("en-CA", {
    timeZone: "America/Denver",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const parts = formatter.formatToParts(new Date());
  const year = parts.find((p) => p.type === "year").value;
  const month = parts.find((p) => p.type === "month").value;
  const day = parts.find((p) => p.type === "day").value;
  return `${year}-${month}-${day}`;
};

const Doc = () => {
  const [formType, setFormType] = useState("Quotation");
  const [client, setClient] = useState("");
  const [quoteDate, setQuoteDate] = useState(getMountainTimeDate());
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const handleAddItem = (item) => {
    if (selectedItem) {
      setItems((prev) =>
        prev.map((el, index) => (index === selectedItem.index ? item : el))
      );
    } else {
      setItems((prev) => [...prev, item]);
    }
    setModalVisible(false);
    setSelectedItem(null);
  };

  const handleDeleteItem = () => {
    if (selectedItem) {
      setItems((prev) =>
        prev.filter((_, index) => index !== selectedItem.index)
      );
      setSelectedItem(null);
      setModalVisible(false);
    }
  };

  const calculateSummary = () => {
    const subtotal = items.reduce((sum, item) => {
      const quantity = isNaN(parseFloat(item.qty)) ? 1 : parseFloat(item.qty);
      const unitCost = isNaN(parseFloat(item.unitCost))
        ? 0
        : parseFloat(item.unitCost);
      return sum + quantity * unitCost;
    }, 0);

    const gst = subtotal * 0.05;
    const total = subtotal + gst;

    return { subtotal, gst, total };
  };

  const handleQuoteDateChange = (e) => {
    const inputDate = e.target.value;
    const isValidDate = !isNaN(new Date(inputDate).getTime());
    setQuoteDate(isValidDate ? inputDate : getMountainTimeDate());
  };

  const handleGeneratePDF = async () => {
    const doc = new jsPDF();
    const summary = calculateSummary();

    const loadImage = async (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    try {
      const logo = await loadImage(
        require("../../../assets/branding/logo-full-black-2.png")
      );

      // Add Company Logo
      const logoWidth = 87;
      const logoHeight = 20;
      const logoX = 12;
      const logoY = 10;

      doc.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Add Form Type
      doc.setFontSize(18);
      doc.text(formType === "Invoice" ? "INVOICE" : "QUOTATION", 196, 20, {
        align: "right",
      });

      // Add Client Info and Quote Date
      const tableStartX = 14;
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Client:", tableStartX, 43);
      doc.setFont("helvetica", "normal");
      doc.text(client, tableStartX + 15, 43);

      doc.setFont("helvetica", "bold");
      doc.text("Quote Date:", tableStartX, 50);

      doc.setFont("helvetica", "normal");
      const mountainTimeFormatter = new Intl.DateTimeFormat("en-US", {
        timeZone: "America/Denver",
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
      doc.text(
        mountainTimeFormatter.format(new Date(`${quoteDate}T00:00:00`)),
        tableStartX + 26,
        50
      );

      // Add Item Table
      const tableData = items.map((item, index) => {
        const quantity = isNaN(parseFloat(item.qty)) ? 1 : parseFloat(item.qty);
        const unitCost = isNaN(parseFloat(item.unitCost))
          ? 0
          : parseFloat(item.unitCost);
        const total = quantity * unitCost;

        return [
          index + 1,
          item.description || "",
          item.unit || "",
          item.qty || "",
          `${unitCost.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          `${total.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        ];
      });

      doc.autoTable({
        head: [["#", "Description", "Unit", "Qty", "Unit Cost", "Total"]],
        body: tableData,
        startY: 57,
        startX: tableStartX,
        theme: "grid",
        styles: { fontSize: 10, lineWidth: 0.1, lineColor: 0 },
        headStyles: {
          fillColor: [240, 240, 240],
          textColor: 0,
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
          halign: "center",
        },
        columnStyles: {
          0: { halign: "center", valign: "middle" },
          2: { halign: "center", valign: "middle" },
          3: { halign: "center", valign: "middle" },
          4: { halign: "center", cellWidth: 30, valign: "middle" },
          5: {
            fontStyle: "bold",
            cellWidth: 30,
            halign: "center",
            valign: "middle",
          },
        },
      });

      // Add Summary Table
      const finalY = doc.lastAutoTable.finalY + 7;
      const pageWidth = doc.internal.pageSize.width;
      const summaryStartX = pageWidth - 92;

      doc.autoTable({
        head: [],
        body: [
          [
            "Subtotal",
            `$${summary.subtotal.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
          ],
          [
            "GST (5%)",
            `$${summary.gst.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
          ],
          [
            "Total Cost",
            `$${summary.total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
          ],
        ],
        startY: finalY,
        margin: { left: summaryStartX + 18 },
        theme: "grid",
        columnStyles: {
          0: { cellWidth: 30, fillColor: [240] },
          1: { cellWidth: 30, fontStyle: "bold" },
        },
        styles: {
          fontSize: 10,
          halign: "right",
          lineColor: 0,
        },
        didParseCell: (data) => {
          if (data.row.index === 2) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      // Add Footer
      const footerY = finalY + 26;
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text("Express Granite and Quartz", tableStartX, footerY - 23);
      doc.setFont("helvetica", "normal");
      doc.text("Calgary, AB, Canada", tableStartX, footerY - 18);
      doc.text(
        "Website: expressgraniteandquartz.com",
        tableStartX,
        footerY - 10
      );
      doc.text("Phone: 1-403-818-8961", tableStartX, footerY - 5);
      doc.text(
        "Email: expressgraniteandquartz@gmail.com",
        tableStartX,
        footerY
      );

      let fileName =
        (client ? `${client} - ` : "") +
        formType.toUpperCase() +
        ` - ${quoteDate}.pdf`;
      doc.save(fileName);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className="doc-container">
      <h1>Create New {formType}</h1>
      <div className="doc-form-fields">
        <div className="doc-form-row">
          <FormField
            label="Client"
            type="text"
            value={client}
            onChange={(e) => setClient(e.target.value)}
          />
        </div>
        <div className="doc-form-row">
          <FormField
            label="Form Type"
            type="select"
            options={["Quotation", "Invoice"]}
            value={formType}
            onChange={(e) => setFormType(e.target.value)}
          />
          <FormField
            label="Quote Date"
            type="date"
            value={quoteDate}
            onChange={handleQuoteDateChange}
          />
        </div>
      </div>

      <Table
        items={items.map((item) => {
          const quantity = isNaN(parseFloat(item.qty))
            ? 1
            : parseFloat(item.qty);
          const unitCost = isNaN(parseFloat(item.unitCost))
            ? 0
            : parseFloat(item.unitCost);
          const total = quantity * unitCost;

          return {
            ...item,
            total,
          };
        })}
        onRowClick={(index) => {
          setSelectedItem({ ...items[index], index });
          setModalVisible(true);
        }}
      />

      <div className="doc-add-button-container">
        <FontAwesomeIcon
          icon={faPlusCircle}
          className="doc-add-button"
          color="#ff9800"
          size="xl"
          onClick={() => {
            setModalVisible(true);
            setSelectedItem(null);
          }}
        />
      </div>

      {modalVisible && (
        <Modal
          onClose={() => setModalVisible(false)}
          onSave={handleAddItem}
          item={selectedItem}
          onDelete={handleDeleteItem}
        />
      )}

      {items.length > 0 && (
        <>
          <Summary summary={calculateSummary()} />
          <button className="doc-test-button" onClick={handleGeneratePDF}>
            {formType === "Invoice" ? "Create Invoice" : "Create Quotation"}
          </button>
        </>
      )}
    </div>
  );
};

export default Doc;
